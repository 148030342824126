<template>
	<div>
		<!-- <HeaderTitle :content="{ title: 'Current Issue', subtitle: 'Volume 1, Issue 1' }" :onlyGradient="!isArticle" />
		<Container class="px-6 pb-12 overflow-auto md:px-10 md:pb-12 xl:pb-20 xl:px-24 shadow-article">
			<template v-if="!isArticle && articles">
				<ArticlePreview v-for="article of articles" :key="article.id" :article="article" class-list="mb-16" />
			</template>
			<template v-else-if="isArticle && articles">
				<ArticlePage :article="getArticleBySlug(page.slug || '')[0]" />
			</template>
		</Container> -->
		<template v-if="!isArticle && articles">
			<ArticlePreview v-for="article of articles" :key="article.id" :article="article" class-list="mb-16" />
		</template>
		<template v-else-if="isArticle && articles">
			<ArticlePage :article="getArticleBySlug(page.slug || '')[0]" />
		</template>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { PageType } from '@/types/PageType'
import type { Article } from '@/types/Article'
import axios from 'axios'
import ArticlePreview from '@/components/layout/ArticlePreview.vue'
import ArticlePage from './ArticlePage.vue'


export default defineComponent({
	components: {
		ArticlePreview, ArticlePage
	},
	props: {
		page: {
			type: Object as PropType<PageType>,
			required: true
		}
	},
	data() {
		return {
			articles: undefined as undefined | Article[]
		}
	},
	computed: {
		isArticle(): boolean {
			return !!this.page.slug
		}
	},
	methods: {
		async loadArticle() {
			try {
				const { data } = await axios.get(`/content/${this.page.id}`)

				this.articles = data
			} catch (err) {
				//
			}
		},
		getArticleBySlug(slug: string): Article[] {
			return this.articles!.filter((article: Article) => article.slug === slug)
		}
	},
	created() {
		this.loadArticle()
	}
})
</script>