import { defineStore } from 'pinia'
import type { RootState } from '@/types/RootState'
import axios from 'axios'


export const useGlobalStore = defineStore('global', {
	state: () => ({
		searchboxOpen: false,
		header: {
			title: '',
			subtitle: '',
			onlyGradient: false
		},
		bearer: null,
		user: null
	}) as RootState,
	getters: {
		isLoggedIn(): boolean {
			return this.bearer !== null && this.user !== null
		}
	},
	actions: {
		setHeader(title: string = '', subtitle: undefined | string = undefined, onlyGradient: boolean = false) {
			this.header = {
				title,
				subtitle,
				onlyGradient
			}
		},
		setBearer(token: string | null) {
			this.bearer = token

			if (token) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			} else {
				delete axios.defaults.headers.common['Authorization'];
			}
		}
	},
	persist: {
		key: 'hp-persist',
		afterRestore(context) {
			if (context.store.bearer) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${context.store.bearer}`;
			}
		},
	}
})