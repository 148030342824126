<template>
	<div>
		<HeaderTitle :content="{ title: 'Home' }" :onlyGradient="false" />
		<Container
			class="px-6 sm:px-12 xl:px-24 pb-20 overflow-auto shadow-article bg-[url('@/assets/img/home_bg.jpg')] bg-no-repeat bg-70% bg-right-bottom"
		>
			<div class="grid grid-cols-1 gap-16 lg:grid-cols-2 mt-14">
				<div class="">
					<h1
						class="mb-6 text-5xl font-semibold text-center font-adobe-caslon-pro text-hp-900 md:text-left md:pl-14"
					>
						Opening
					</h1>

					<div class="p-5 bg-gradient-to-b from-hp-300 sm:p-14 rounded-2xl">
						<p class="text-justify font-servus-slab text-hp-900">
							The Heim Pál National Pediatric Institute has embarked on a
							pioneering initiative with the launch of its first
							English-language scientific journal entitled Hungarian Pediatrics
							(HP). The founders aim to enhance the encourage the emergence of
							scientific creative communities and provide a publication platform
							for physicians and the wider professional community. The
							pediatrician’s scientific excellence has been recognized both
							nationally and internationally. The publication and citation rates
							of pediatricians have increased significantly in recent years. We
							value the maturation of continuous high quality scientific work
							into doctoral degrees and the ability of graduates to perpetuate
							their knowledge, potentially leading to the establishment of new
							academic schools. <br /><br />
							The Editorial Board will be composed of renowned national and
							international experts, including the professional leaders of our
							Institute. We expect that the articles, dialogues and reports
							published in the journal will reflect the diverse essence of both
							Hungarian and foreign scientific culture. Therefore, we consider
							it very important that the information presented in the journal
							reaches not only a Hungarian but also an international audience.
							<br /><br />
							The journal accepts a wide range of high-quality submissions,
							including original medical and clinical research papers, review
							articles, case reports, opinions, and editorials, ensuring that
							readers have access to a diverse range of content. As an open
							access journal, Hungarian Pediatrics offers free and unrestricted
							access to its content, allowing researchers and practitioners from
							around the world to benefit from the latest research and
							developments in the field of Pediatrics.
						</p>
					</div>

					<div class="ml-auto font-semibold text-right text-hp-900 font-utile">
						<p class="text-3xl">Current Issue</p>
						<p class="text-lg small-caps">{{ issue }}</p>
					</div>
				</div>
				<div>
					<RouterLink to="/articles/recent">
						<img
							:src="image"
							class="mx-auto transition-all lg:mx-0 hover:scale-105"
						/>
					</RouterLink>
				</div>
			</div>
		</Container>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import axios from "axios"

export default defineComponent({
	data() {
		return {
			issue: "",
			image: "",
		}
	},
	methods: {
		async loadHomepage() {
			axios
				.get("/meta/homepage")
				.then((response) => {
					if (response.status === 200) {
						const data = response.data

						this.issue = data?.current_issue ?? ""
						this.image = data?.img ?? ""
					}
				})
				.catch((err) => {
					console.error(err)
				})
		},
	},
	created() {
		this.loadHomepage()
	},
})
</script>
