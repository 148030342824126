<template>
	<div class="pt-10">
		<!-- <HeaderTitle :content="{ title: article.title }" />
		<Container class="px-6 pt-8 pb-12 overflow-auto md:px-10 md:pt-10 md:pb-12 xl:pb-20 xl:pt-14 xl:px-24 shadow-article">
			<article class="font-utile" v-html="article.content">
				
			</article>
		</Container> -->
		<div v-if="article === 'empty'" class="pt-8 pb-24 font-utile">
			<h2 class="mb-4 text-2xl font-semibold text-hp-900">This page is coming soon...</h2>
			<p>We're working tirelessly behind the scenes to bring you a cutting-edge medical resource. Stay tuned for insightful articles, expert advice, and the latest advancements in healthcare. Get ready to explore a world of wellness and knowledge, designed to empower and inform. Your journey to better health begins here, launching soon!</p>
		</div>
		<article v-else-if="article" class="font-utile" v-html="article.content">
		</article>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { PageType } from '@/types/PageType'
import type { Article } from '@/types/Article'
import axios from 'axios'
import { useGlobalStore } from '@/stores/global'
import { mapStores } from 'pinia'

export default defineComponent({
	props: {
		page: {
			type: Object as PropType<PageType>,
			required: true
		}
	},
	data() {
		return {
			article: undefined as undefined | Article | "empty"
		}
	},
	computed: {
		...mapStores(useGlobalStore)
	},
	methods: {
		async loadArticle() {
			try {
				const { data } = await axios.get(`/content/${this.page.id}`)

				if (data.length > 0) {
					this.article = data[0]
					/* this.globalStore.setHeader(this.article?.title) */
				} else {
					this.article = 'empty'
				}
			} catch (err) {
				//
			}
		}
	},
	created() {
		this.loadArticle()
	}
})
</script>