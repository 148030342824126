<template>
	<li>
		<a @click="logout" :class="classes">Logout</a>
	</li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { NavigationItem } from '@/types/NavigationItem'
import { twMerge } from 'tailwind-merge'
import { useGlobalStore } from "@/stores/global"
import { mapStores } from "pinia"
import axios from 'axios'

export default defineComponent({
	props: {
		className: {
			type: String,
			required: false
		}
	},
	methods: {
		async logout() {
			try {
				await axios.get('/auth/logout')

				this.globalStore.setBearer(null)
				this.globalStore.user = null

				this.$router.replace({name: 'login'})
			} catch (err: any) {
				// TODO: szebb hibajelzés
			}
		}
	},
	computed: {
		classes(): string {
			return twMerge('block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg cursor-pointer lg:relative lg:px-4 lg:py-1 lg:pt-2 lg:transition-all lg:rounded-2xl lg:hover:bg-white lg:hover:text-hp-900 hover:bg-gray-50 lg:text-white lg:text-md xl:text-2xl lg:font-medium lg:mx-0', this.className)
		},
		...mapStores(useGlobalStore)
	},
})
</script>