<template>
	<footer class="pt-10 pb-10 bg-gradient-to-b from-hp-600 to-hp-200 rounded-t-4xl">
		<div class="mb-8">
			<ul class="flex flex-col max-w-xs mx-auto text-center text-white cursor-pointer sm:max-w-none sm:flex-row sm:justify-center">
				<li class="relative my-1 transition-all cursor-pointer sm:my-0 hover:text-hp-900 hover:bg-white rounded-xl">
					<RouterLink class="grid items-center px-4 py-2" :to="{name: 'contact'}">Contact</RouterLink>
				</li>
				<li><div class="h-full mx-3 border border-white"></div></li>
				<li class="relative my-1 transition-all cursor-pointer hover:text-hp-900 hover:bg-white rounded-xl sm:my-0">
					<RouterLink class="grid items-center px-4 py-2" to="/cookie-policy">Cookie Policy</RouterLink>
				</li>
				<li><div class="h-full mx-3 border border-white"></div></li>
				<li class="relative my-1 transition-all cursor-pointer hover:text-hp-900 hover:bg-white rounded-xl sm:my-0">
					<RouterLink class="grid items-center px-4 py-2" to="/terms-of-use">Terms of Use</RouterLink>
				</li>
				<li><div class="h-full mx-3 border border-white"></div></li>
				<li class="relative my-1 transition-all cursor-pointer hover:text-hp-900 hover:bg-white rounded-xl sm:my-0">
					<RouterLink class="grid items-center px-4 py-2" to="/privacy-policy">Privacy Policy</RouterLink>
				</li>
			</ul>
		</div>
		<div class="container mx-auto">
			<p class="px-10 mx-auto mb-2 text-sm font-light text-center text-white w-fit font-utile">
				Copyright &copy; {{ currentYear }} Promenade Publishing House. All rights reserved. The content on this site is intended for healthcare professionals.
			</p>
			<p class="px-10 mx-auto text-sm font-light text-center text-white w-fit font-utile">Hungarian Pediatrics Open Access Journal<br>ISSN: HU-ISSN 3004-0272</p>
		</div>
	</footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	computed: {
		currentYear(): number {
			return (new Date()).getFullYear()
		}
	}
})
</script>