import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import ToastPlugin from 'vue-toast-notification'
import VueGtag from 'vue-gtag-next'
import { useRemember } from '@/composables/remember'

import App from '@/App.vue'
import { createCustomRouter } from '@/router'

import ContentContainer from './components/base/ContentContainer.vue'
import HeaderTitle from './components/layout/HeaderTitle.vue'

import '@/assets/style/fonts.css'
import '@/assets/style/article.css'
import '@/assets/style/toast.css'


const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)

const { remember } = useRemember()
const initializeApp = async () => {
	await remember()


	const router = createCustomRouter()
	app.use(router)

	// Mount
	app.mount('#app')
}


axios.defaults.baseURL = import.meta.env.VITE_API_PATH

// Add components to VUE
// eslint-disable-next-line vue/multi-word-component-names
app.component('Container', ContentContainer)
app.component('HeaderTitle', HeaderTitle)

app.use(VueCookies)
app.use(ToastPlugin)

// TODO: Bekötni a cookie consentbe
app.use(VueGtag, {
	property: {
		id: 'G-VMNZX4WK4H'
	}
})

initializeApp()