import type { RouteRecordRaw } from 'vue-router'
import type { AuthType } from '@/types/AuthType'

import HomePage from '@/pages/HomePage.vue'
import ComponentRouter from '@/components/ComponentRouter.vue'


const routes: RouteRecordRaw[] = [
	{ path: '/', name: 'home', component: HomePage, meta: { requiresAuth: 'public' as AuthType } },

	{ path: '/search', name: 'search', component: () => import('@/pages/SearchPage.vue'), meta: { requiresAuth: 'requires-auth' as AuthType } },
	{ path: '/articles/press', name: 'article-in-press', component: () => import('@/pages/articles/ArticlesInPress.vue'), meta: { requiresAuth: 'requires-auth' as AuthType } },
	{ path: '/articles/previous-issues', name: 'previous-articles', component: () => import('@/pages/articles/PreviousArticles.vue'), meta: { requiresAuth: 'requires-auth' as AuthType } },

	{ path: '/register', name: 'register', component: () => import('@/pages/RegisterPage.vue'), meta: { requiresAuth: 'requires-unauth' as AuthType } },
	{ path: '/login', name: 'login', component: () => import('@/pages/LoginPage.vue'), meta: { requiresAuth: 'requires-unauth' as AuthType } },
	{ path: '/profile', name: 'profile', component: () => import('@/pages/ProfilePage.vue'), meta: { requiresAuth: 'requires-auth' as AuthType } },

	{ path: '/activate', name: 'activate', component: () => import('@/pages/ActivateUser.vue'), meta: { requiresAuth: 'requires-unauth' as AuthType } },
	{ path: '/forgot-password', name: 'forgot-password', component: () => import('@/pages/ForgotPasswordPage.vue'), meta: { requiresAuth: 'requires-unauth' as AuthType } },
	{ path: '/password-recovery', name: 'password-recovery', component: () => import('@/pages/PasswordRecoveryPage.vue'), meta: { requiresAuth: 'requires-unauth' as AuthType } },

	{ path: '/contact', name: 'contact', component: () => import('@/pages/ContactPage.vue'), meta: { requiresAuth: 'public' as AuthType } },
	{ path: '/cookie-policy', name: 'cookie-policy', component: () => import('@/pages/static/CookiePolicyPage.vue'), meta: { requiresAuth: 'public' as AuthType } },
	{ path: '/terms-of-use', name: 'terms-of-use', component: () => import('@/pages/static/TermsOfUsePage.vue'), meta: { requiresAuth: 'public' as AuthType } },
	{ path: '/privacy-policy', name: 'privacy-policy', component: () => import('@/pages/static/PrivacyPolicyPage.vue'), meta: { requiresAuth: 'public' as AuthType } },

	{ path: '/:pathMatch(.*)*', component: ComponentRouter, name: 'component-router', meta: { requiresAuth: 'public' as AuthType } }
]

export default routes