<template>
	<div v-if="!consent"
		class="fixed inset-x-0 bottom-0 z-50 flex flex-col justify-between p-6 bg-white gap-x-8 gap-y-4 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
		<p class="max-w-4xl text-sm leading-6 text-gray-900">We use cookies to enhance your browsing experience! By clicking 'Accept All,' you consent to the use of all cookies on this site. For more information, please read our <router-link :to="{ name: 'home' }"
				class="font-semibold text-hp-600">Cookie Policy</router-link>.</p>
		<div class="flex items-center flex-none gap-x-5">
			<button @click="saveConsent('all')" type="button"
				class="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-hp-900 hover:bg-hp-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hp-900">Accept all cookies</button>
			<button @click="saveConsent('required')" type="button"
				class="text-sm font-semibold leading-6 text-gray-900">Accept only required cookies</button>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

type ConsentType = 'all' | 'required'

/**
 * Cookie Consent
 */
export default defineComponent({
	data() {
		return {
			consent: false
		}
	},
	computed: {
		cookieName(): string {
			return 'cookie_consent'
		}
	},
	methods: {
		saveConsent(consent: ConsentType) {
			let cookie = consent === 'all' ?? 'required'

			this.$cookies.set(this.cookieName, cookie, '1y')

			this.consent = true
		}
	},
	created() {
		if (this.$cookies.isKey(this.cookieName)) {
			this.consent = true
		}
	}
})
</script>