import { defineStore } from 'pinia'

export const useLightboxStore = defineStore('lightbox', {
	state: () => ({
		visible: false,
		images: [] as string[] | string,
		currentIndex: 0
	}),
	actions: {
		setLightboxImages(image: string) {
			this.images = image
		},
		toggleLightbox(lightboxState: boolean) {
			this.visible = lightboxState
		}
		/* open(images: string[], index: number = 0) {
			this.images = images
			this.currentIndex = index
			this.visible = true
		},
		close() {
			this.visible = false
		},
		next() {
			this.currentIndex = (this.currentIndex + 1) % this.images.length
		},
		previous() {
			this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length
		} */
	}
})