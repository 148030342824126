import axios from 'axios'
import { useGlobalStore } from '@/stores/global'

export const useRemember = () => {
	const globalStore = useGlobalStore()

	const remember = async () => {
		if (globalStore.isLoggedIn) {
			try {
				// TODO: Use the response user data, and provide route based checking
				const response = await axios.get('/auth/user')
			} catch (e) {
				globalStore.setBearer(null)
				globalStore.user = null
			}
		}
	}

	return {
		remember
	}
}