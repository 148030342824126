<template>
	<div class="flex flex-col justify-center flex-auto w-full px-6 py-16 pb-24 mx-auto max-w-7xl sm:py-40 lg:px-8 font-utile">
		<p class="text-xl font-semibold leading-8 text-hp-900">404</p>
		<h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">The requested page was not found</h1>
		<p class="max-w-3xl mt-6 text-base leading-7 text-gray-600">
			Oops! The page you're searching for has vanished into the digital wilderness. But fret not, there's plenty more to explore on our site. Use the menu or search bar to find your way back.</p>
		<div class="mt-10">
			<a @click.prevent="goBack()" class="text-lg font-semibold leading-7 cursor-pointer text-hp-900 hover:text-hp-600">
				<span aria-hidden="true" class="mr-1">&larr;</span> Back</a>
			<router-link :to="{ name: 'home' }" class="flex items-center text-lg font-semibold leading-7 text-hp-900 hover:text-hp-600">
				<span aria-hidden="true" class="mr-1.5"><HomeIcon class="w-4 pb-0.5" /></span> Back to home </router-link>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { HomeIcon } from '@heroicons/vue/20/solid';

export default defineComponent({
	components: {
		HomeIcon
	},
	methods: {
		goBack(): void {
			this.$router.go(-1)
		}
	}
})
</script>