<template>
	<div>
		<vue-easy-lightbox
			:visible="visibleRef"
			:imgs="imgsRef"
			:index="indexRef"
			@hide="onHide"
			>
		</vue-easy-lightbox>
	</div>
</template>

<script setup lang="ts">
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref, computed } from 'vue'

import { useLightboxStore } from '@/stores/lightbox'
const lightboxStore = useLightboxStore()

const visibleRef = computed(() => lightboxStore.visible)
const indexRef = ref(0)
const imgsRef = computed(() => lightboxStore.images)

const onHide = () => {
	lightboxStore.toggleLightbox(false)
}
</script>