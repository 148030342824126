<template>
	<div class="relative">
		<Menu v-slot="{ open }">
		<MenuButton as="a" role="button" :class="{ 'bg-white lg:!text-hp-900 underline underline-offset-4 lg:no-underline': open }" 
			class="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg cursor-pointer lg:relative lg:px-4 lg:py-1 lg:pt-2 lg:transition-all lg:rounded-2xl lg:hover:bg-white lg:hover:text-hp-900 hover:bg-gray-50 lg:text-white lg:text-md xl:text-2xl lg:font-medium lg:mx-0">{{ navigation.name }}</MenuButton>
		<MenuItems @click="$emit('close-mobile-menu')" class="w-full lg:w-fit lg:absolute lg:left-0 lg:z-40 lg:px-2 lg:py-3 lg:bg-white lg:shadow-md lg:rounded-2xl lg:top-12 group-hover:block whitespace-nowrap focus-visible:outline-none">
			<HMenuItem v-slot="{ close }" as="li" v-for="nav of navigation.children" :key="nav.id">
				<MenuItem @click="close" :navigation="nav" className="block pl-6 !font-normal lg:px-4 lg:py-1 lg:hover:!bg-gray-100 lg:rounded-xl lg:!text-hp-900" />
			</HMenuItem>
		</MenuItems>
	</Menu>
	<!-- class="block pl-6 !font-normal lg:px-4 lg:py-1 lg:hover:!bg-gray-100 lg:rounded-xl lg:!text-hp-900" -->
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { NavigationItem } from '@/types/NavigationItem'
import { Menu, MenuButton, MenuItems, MenuItem as HMenuItem } from '@headlessui/vue'
import MenuItem from './MenuItem.vue'

export default defineComponent({
	emits: ['close-mobile-menu'],
	components: {
		// eslint-disable-next-line vue/no-reserved-component-names
		Menu, MenuButton, MenuItems, HMenuItem, MenuItem
	},
	props: {
		navigation: {
			type: Object as PropType<NavigationItem>,
			required: true
		}
	},
	methods: {
		
	}
})
</script>