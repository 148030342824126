<template>
	<div :class="classes">
		<slot></slot>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { twMerge } from 'tailwind-merge'


export default defineComponent({
	props: {
		classList: {
			type: String,
			required: false
		}
	},
	computed: {
		classes(): string {
			return twMerge('article-container container mx-auto 2xl:max-w-[1280px] bg-white', this.classList)
		}
	}
})
</script>