<template>
	<div :class="baseClasses" class="group">
		<!-- Header -->
		<div class="relative mb-10">
			<div class="w-full h-12 bg-gradient-to-t from-hp-600 to-hp-200 rounded-t-2xl group-first:rounded-none" />
			<div class="w-full h-8 bg-hp-100 rounded-b-2xl flex items-center justify-between px-10 font-utile font-semibold text-xs sm:text-lg pt-0.5">
				<!-- <p>{{ article.meta?.year }} VOLUME: {{ article.meta?.volume }} ISSUE: {{ article.meta?.issue }}</p> -->
				<p class="text-slate-700">{{ article.meta?.year }} <span class="headline-separator"></span> {{ 'VOLUME: ' + article.meta?.volume }} <span class="headline-separator"></span> {{ 'ISSUE: ' + article.meta?.issue }}</p>
				<p class="hidden uppercase sm:block text-slate-700">{{ article.category?.name }}</p>
			</div>
			<p class="pt-2 text-sm font-semibold pl-28 sm:hidden font-utile text-slate-700">{{ article.category?.name }}</p>
		</div>

		<!-- Title -->
		<h2 class="pb-10 text-xl sm:text-3xl text-hp-900 font-servus-slab" v-html="article.title"></h2>

		<!-- Doctors -->
		<template v-if="article.leading_content">
			<div class="p-6 text-xs font-medium leading-5 bg-gray-200 leading_content sm:text-sm text-hp-900 font-utile" v-html="article.leading_content">
			</div>

			<div class="w-full my-6 border-t border-hp-600 opacity-70" />
		</template>

		<!-- Abstract -->
		<template v-if="article.summary">
			<div class="bg-[#daeffc] text-hp-900 p-6 leading-5 text-xs sm:text-sm font-utile font-medium" v-html="article.summary">
			</div>

			<div class="w-full my-6 border-t border-hp-600 opacity-70" />
		</template>

		<!-- Actions -->
		<div class="flex justify-between w-full text-sm font-medium text-center text-white sm:text-2xl bg-hp-100 rounded-2xl">
			<RouterLink :to="contentPath" class="py-2 transition-all w-36 sm:w-64 small-caps bg-gradient-to-r from-hp-600 to-hp-300 rounded-l-2xl hover:bg-hp-700">Full Article</RouterLink>
			<a v-if="typeof pdfPath === 'string'" :href="pdfPath" target="_blank" download class="py-2 cursor-pointer w-36 sm:w-64 small-caps bg-gradient-to-l from-hp-600 to-hp-300 rounded-r-2xl">Open PDF Viewer</a>
			<a v-else class="py-2 text-white text-opacity-50 cursor-default w-36 sm:w-64 small-caps bg-gradient-to-l from-hp-600 to-hp-300 rounded-r-2xl">Open PDF Viewer</a>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { twMerge } from 'tailwind-merge'
import type { PropType } from 'vue'
import type { Article } from '@/types/Article'
import MakeUriPath from '@/utils/MakeUriPath'

export default defineComponent({
	props: {
		classList: {
			type: String,
			required: false
		},
		article: {
			type: Object as PropType<Article>,
			required: true
		}
	},
	computed: {
		baseClasses(): string {
			return twMerge('overflow-auto', this.classList)
		},
		contentPath(): string {
			return this.$route.fullPath + '/' + this.article.slug
		},
		pdfPath(): string | null {
			return this.article.meta?.pdf ? MakeUriPath(this.article.meta?.pdf) : null
		}
	}
})
</script>

<style>
hr {
	@apply border-gray-300 my-2
}

.headline-separator {
	@apply inline-block w-1 h-1 sm:w-1.5 sm:h-1.5 rotate-45 bg-hp-700 align-middle mx-0.5 sm:mx-1
}

.leading_content h2 {
	@apply text-base font-bold
}
</style>