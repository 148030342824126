<template>
	<header class="inset-x-0 top-0 z-40 relative transition-all bg-[url('@/assets/img/header_background.jpg')] bg-no-repeat bg-cover">
		<!-- Left design box -->
		<div class="absolute left-0 z-30 h-full padding-box t-0 bg-hp-900" />

		<!-- Top design box -->
		<div class="absolute left-0 z-20 w-full t-0">
			<div class="w-full h-6 bg-hp-700"></div>
			<div class="w-full h-5 bg-gradient-to-r from-[#c2e5fb]"></div>
		</div>

		<!-- Title and subtitle box -->
		<div class="pt-16 pb-5">
			<div class="container relative flex items-center justify-between mx-auto">
				<div class="w-full">
					<img src="@/assets/img/header_title.svg" class="relative h-20 md:h-32 z-40 mb-12 pl-2.5 md:pl-1.5" alt="Hungarian Pediatrics healthcare professional website">
					<div class="relative bg-gradient-to-r from-[#90d3f6] pb-1">
						<div class="absolute h-5 w-full bg-gradient-to-r from-[#c2e5fb] -top-6" />
						<img src="@/assets/img/header_subtitle.png" class="relative h-8 sm:h-10 md:h-12 z-40 pl-0 sm:-ml-2.5 md:-ml-1.5" alt="For the perfect knowledge">
					</div>
				</div>
				<img src="@/assets/img/header_kids.png" class="absolute right-0 hidden w-1/3 -bottom-5 md:block" alt="Design image that shows happy children">
			</div>
		</div>
	</header>
	<nav class="sticky top-0 z-40 px-4 text-white bg-gradient-to-t from-hp-600 to-hp-200 font-utile-narrow">
		<div class="container mx-auto">
			<!-- Desktop menu -->
			<ul class="items-center justify-center hidden py-2 font-normal text-md lg:flex gap-x-4 xl:text-2xl small-caps">
				<!-- Navigation for desktop -->
				<component v-for="nav of navigation" :key="nav.id" :is="nav.type" :navigation="nav" />

				<li>
					<a @click="openSearchBox()" role="button" class="relative flex items-center px-4 py-1 pt-2 transition-all cursor-pointer rounded-2xl hover:bg-white hover:text-hp-900">
						<MagnifyingGlassIcon class="h-6 pb-1 mr-1" /> Search
					</a>
				</li>

				<template v-if="globalStore.isLoggedIn">
					<li>
						<InternalMenuItem to="/profile">Profile</InternalMenuItem>
					</li>
					<li>
						<LogoutMenuItem />
					</li>
				</template>
				<template v-else>
					<InternalMenuItem class-name="bg-white hover:!bg-opacity-80 !text-hp-900" to="/login">Login</InternalMenuItem>
				</template>
			</ul>

			<!-- Mobile menu -->
			<ul class="flex items-center justify-between py-2 text-xl lg:hidden">
				<li>
					<p class="font-adobe-caslon-pro font-semibold pt-[.15rem] cursor-default">Hungarian Pediatrics</p>
				</li>
				<li>
					<button @click="mobileMenuOpen = true" type="button" class="text-xl rounded-2xl px-2 py-1.5 transition-all hover:bg-white hover:text-hp-900">
						<span class="sr-only">Open main menu</span>
						<Bars3Icon class="h-7 w-7" aria-hidden="true" />
					</button>
				</li>
			</ul>

			<MobileMenu :navigation="navigation" :menu-state="mobileMenuOpen" 
			@close="closeMobileMenu()" @open-search-box="openSearchBox()" />
		</div>

		<!-- Bottom design box -->
		<div class="absolute left-0 w-full h-8 bg-hp-100 rounded-b-3xl shadow-header" />
	</nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useGlobalStore } from '@/stores/global'
import { mapStores } from 'pinia'

import { Bars3Icon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'

import MobileMenu from './MobileMenu.vue'
import axios from 'axios'
import MenuItem from './menu/MenuItem.vue'
import DropdownMenuItem from './menu/DropdownMenuItem.vue'
import type { NavigationItem } from '@/types/NavigationItem'
import BaseSkeletonLoader from '../skeleton-loader/BaseSkeletonLoader.vue'
import LogoutMenuItem from './menu/LogoutMenuItem.vue'
import InternalMenuItem from './menu/InternalMenuItem.vue'


export default defineComponent({
	components: {
		// eslint-disable-next-line vue/no-unused-components
		Bars3Icon, MagnifyingGlassIcon, MobileMenu, MenuItem, DropdownMenuItem, BaseSkeletonLoader, LogoutMenuItem, InternalMenuItem
	},
	data() {
		return {
			mobileMenuOpen: false,
			navigation: undefined as undefined | NavigationItem[]
		}
	},
	computed: {
		searchbox(): boolean {
			return this.globalStore.searchboxOpen
		},
		...mapStores(useGlobalStore)
	},
	methods: {
		closeMobileMenu(): void {
			this.mobileMenuOpen = false
		},
		openSearchBox() {
			this.globalStore.searchboxOpen = true
		},
		async loadHeader() {
			try {
				const { status, data } = await axios.get<Awaited<NavigationItem[]>>('/layout/header')
			
				if (status !== 200)
					throw new Error('Error when fetching: ' + status)

				this.navigation = data
			} catch (err) {
				// TODO: Hibaketelés
			}
		}
	},
	watch: {
		'searchbox'(state: boolean) {
			if (!state) this.closeMobileMenu()
		}
	},
	created() {
		this.loadHeader()
	}
})
</script>

<style scoped>
.padding-box {
	--gap: 3.8rem;

	@apply w-11
		xs:w-[calc(((100%-375px)*0.5)+2.75rem)]
		sm:w-[calc(((100%-640px)*0.5)+2.75rem)]
		md:w-[calc(((100%-768px)*0.5)+var(--gap))]
		lg:w-[calc(((100%-1024px)*0.5)+var(--gap))]
		xl:w-[calc(((100%-1280px)*0.5)+var(--gap))]
		2xl:w-[calc(((100%-1536px)*0.5)+var(--gap))]
}
</style>

<style>
.router-link-active:not(.dml)::after {
	content: '';
	background-color: white;
	position: absolute;
	width: calc( 100% - 2rem );
	height: 1.5px;
	left: 50%;
	bottom: 8px;
	transform: translateX(-50%);
}
</style>