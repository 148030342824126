<template>
	<div>
		<NotFoundPage v-if="page === 'not-found'" />

		<template v-else>
			<HeaderTitle :content="globalStore.header" :onlyGradient="globalStore.header.onlyGradient" />
			<Container class="px-6 pb-12 overflow-auto md:px-10 md:pb-12 xl:pb-20 xl:px-24 shadow-article min-h-[50vh]">
				<ArticleSkeletonLoader v-if="page === undefined" />
				<component v-else :is="page.type" :page="page" />
			</Container>
		</template>
	</div>
</template>

<script lang="ts">
import type { PageType } from '@/types/PageType'
import { defineComponent } from 'vue'
import axios from 'axios'
import ArticleSkeletonLoader from './skeleton-loader/ArticleSkeletonLoader.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import PostPage from '@/pages/PostPage.vue'
import BlogPage from '@/pages/BlogPage.vue'
import HeaderTitle from './layout/HeaderTitle.vue'
import { useGlobalStore } from '@/stores/global'
import { useLightboxStore } from '@/stores/lightbox'
import { mapStores } from 'pinia'


export default defineComponent({
	components: {
		// eslint-disable-next-line vue/no-unused-components
		ArticleSkeletonLoader, NotFoundPage, BlogPage, PostPage, HeaderTitle
	},
	data() {
		return {
			page: undefined as undefined | 'not-found' | PageType
		}
	},
	computed: {
		...mapStores(useGlobalStore, useLightboxStore)
	},
	methods: {
		async loadPage() {
			const path = this.$route.fullPath

			try {
				const { data } = await axios.get(`/page${path}`)

				if (!data.public && !this.globalStore.isLoggedIn) {
					this.$router.replace({name: 'login', query:{redirect: this.$route.path}})
					return
				}

				this.page = data as PageType
				this.globalStore.setHeader(this.page.meta?.title, this.page.meta?.subtitle)
			} catch (err: any) {
				if (err?.response?.status === 404) {
					this.page = 'not-found'
				}
			}
		},
		showLightbox(event: Event) {
			const target = event.target as HTMLImageElement

			if (target?.tagName !== 'IMG' || target.classList.contains('graphical-abstract')) {
				return
			}

			const articleElement = target.closest('article')
			if (!articleElement) {
				return
			}

			this.lightboxStore.setLightboxImages(target?.src)
			this.lightboxStore.toggleLightbox(true)
		}
	},
	created() {
		this.loadPage()
	},
	mounted() {
		document.addEventListener('click', this.showLightbox)
	},
	beforeUnmount() {
		document.removeEventListener('click', this.showLightbox)
	},
})
</script>