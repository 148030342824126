<template>
	<div>
		<div class="relative">
			<div class="w-full h-12 gradient-header" />
			<div v-if="onlyGradient === false" class="container mx-auto h-10 md:h-20 2xl:max-w-[1280px] bg-[url('@/assets/img/header_title_bg.jpg')] bg-cover bg-bottom">
				<div class="relative block mx-auto text-center font-utile-narrow md:ml-28 md:mr-auto w-fit text-hp-900 md:text-right"
					:class="{ '-top-8 md:-top-4': content.subtitle !== undefined, '-top-4 md:top-0': content.subtitle == undefined }">
					<h1 class="text-2xl font-semibold sm:text-3xl md:text-4xl lg:text-5xl">{{ content.title }}</h1>
					<h2 class="text-xl font-semibold small-caps">{{ content.subtitle }}</h2>
				</div>
			</div>
			<div v-if="onlyGradient === false" class="container mx-auto mt-1.5 2xl:max-w-[1280px] bg-white">
				<div class="h-8 bg-hp-700 rounded-b-3xl" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type HeaderTitle = {
	title?: string,
	subtitle?: string
}


export default defineComponent({
	props: {
		content: {
			type: Object as PropType<HeaderTitle>,
			required: true
		},
		onlyGradient: {
			type: Boolean,
			required: false,
			default: true
		}
	}
})
</script>

<style scoped>
.gradient-header {
	background: rgba(129,207,245,1);
}

@media (min-width: 1430px) {
	.gradient-header {
		background: rgba(129,207,245,1);
		background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(129,207,245,1) 5%, rgba(129,207,245,1) 50%, rgba(129,207,245,1) 95%, rgba(255,170,40,0) 100%);
	}
}
</style>