<template>
	<li>
		<RouterLink :class="classes"
			:to="navigation.href">{{ navigation.name }}</RouterLink>
	</li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { NavigationItem } from '@/types/NavigationItem'
import { twMerge } from 'tailwind-merge'

export default defineComponent({
	props: {
		navigation: {
			type: Object as PropType<NavigationItem>,
			required: true
		},
		className: {
			type: String,
			required: false
		}
	},
	computed: {
		classes(): string {
			return twMerge('block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg cursor-pointer lg:relative lg:px-4 lg:py-1 lg:pt-2 lg:transition-all lg:rounded-2xl lg:hover:bg-white lg:hover:text-hp-900 hover:bg-gray-50 lg:text-white lg:text-md xl:text-2xl lg:font-medium lg:mx-0', this.className)
		}
	},
})
</script>