<template>
	<div>
		<!-- Header -->
		<div class="relative mb-10">
			<div class="w-full h-12 bg-gradient-to-t from-hp-600 to-hp-200" />
			<div class="w-full h-8 bg-hp-100 rounded-b-2xl flex items-center justify-between px-10 font-utile font-semibold text-sm sm:text-lg pt-0.5">
				<p class="text-slate-700">{{ article.meta?.year }} <span class="headline-separator"></span> {{ 'VOLUME: ' + article.meta?.volume }} <span class="headline-separator"></span> {{ 'ISSUE: ' + article.meta?.issue }}</p>
				<p class="text-slate-700">{{ article.category?.name }}</p>
			</div>
		</div>

		<!-- Title -->
		<h1 class="pb-10 text-xl sm:text-3xl text-hp-900 font-servus-slab" v-html="article.title"></h1>

		<a v-if="article?.meta?.pdf" :href="pdfPath ?? ''" target="_blank" download class="block px-4 py-2 mb-5 font-semibold text-white cursor-pointer w-fit small-caps bg-gradient-to-l from-hp-600 to-hp-300 rounded-2xl">Open PDF Viewer</a>

		<!-- Doctors -->
		<template v-if="article.leading_content">
			<div class="p-6 text-xs font-medium leading-5 bg-gray-200 leading_content sm:text-sm text-hp-900 font-utile" v-html="article.leading_content">
			</div>

			<div class="w-full my-6 border-t border-hp-600 opacity-70" />
		</template>

		<!-- Summary -->
		<div class="bg-[#daeffc] text-hp-900 p-6 leading-5 text-xs sm:text-sm font-utile font-medium" v-html="article.summary">
		</div>

		<div class="w-full my-6 border-t border-hp-600 opacity-70" />

		<article class="font-servus-slab" v-html="article.content">
		</article>

		<div class="mt-10" v-if="article.tailing_content">
			<p class="relative py-1 pt-2 pl-10 text-2xl font-semibold text-white bg-hp-700 w-fit pr-14 small-caps font-utile-narrow rounded-t-2xl top-right-circle">
				References</p>
			<div class="gap-10 p-6 text-sm text-justify text-gray-700 tailing_content abstract bg-hp-100 columns-1 xl:columns-2 " v-html="article.tailing_content">
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Article } from '@/types/Article'
import { useGlobalStore } from '@/stores/global'
import { mapStores } from 'pinia'
import MakeUriPath from '@/utils/MakeUriPath'


export default defineComponent({
	props: {
		article: {
			type: Object as PropType<Article>,
			required: true
		}
	},
	computed: {
		...mapStores(useGlobalStore),
		pdfPath(): string | null {
			return this.article.meta?.pdf ? MakeUriPath(this.article.meta?.pdf) : null
		}
	},
	created() {
		this.globalStore.setHeader(undefined, undefined, true)
	},
	mounted() {
		// TODO: CSS only megoldás
		const figcaptions = document.getElementsByTagName('figcaption')
		for (const caption of figcaptions) {
			const image = caption.parentNode?.children[0]
			const imageWidth = image?.getAttribute('width')

			caption.style.width = imageWidth ? `${imageWidth}px` : 'auto'
		}
	}
})
</script>

<style scoped>
.top-right-circle::after {
	content: url('@/assets/img/Kep_leiras_HP.png');
	display: block;
	position: absolute;
	top: -7px;
	right: -6px;
	scale: .7;
}
</style>

<style>
.abstract p {
	@apply mb-3
}

.tailing_content ol {
	@apply list-decimal pl-4 marker:pr-1
}
.tailing_content ol li {
	@apply mb-2
}
</style>