<template>
	<Container classList="bg-transparent pt-20 pb-44 pb-24 sm:pb-48 md:pb-52">
		<BaseSkeletonLoader class="h-8 mb-6 w-60 md:w-96" />
		<BaseSkeletonLoader class="w-10/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-11/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-11/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-10/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-10/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-11/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-11/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-10/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-9/12 h-4 mb-2" />
		<BaseSkeletonLoader class="w-9/12 h-4" />
	</Container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseSkeletonLoader from './BaseSkeletonLoader.vue';

export default defineComponent({
	components: {
		BaseSkeletonLoader
	}
})
</script>