<template>
	<TransitionRoot :show="searchboxState" as="template" @after-leave="query = ''" appear>
		<Dialog as="div" class="relative z-50 font-utile" @close="close">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
				leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25" />
			</TransitionChild>
			<div class="fixed inset-0 z-10 p-4 mt-10 overflow-y-auto sm:p-6 md:p-20">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
					enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
					leave-to="opacity-0 scale-95">
					<DialogPanel
						class="max-w-2xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5">
						<Combobox @update:modelValue="onSelect">
							<div class="relative">
								<MagnifyingGlassIcon
									class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
									aria-hidden="true" />
								<ComboboxInput
									class="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
									placeholder="Search..." @change="query = $event.target.value"
									@keyup="$event.keyCode === 13 ? onSelect() : null" />
							</div>
							<div class="p-2">
								<h2 class="px-3 mt-4 mb-2 text-xs font-semibold text-gray-500">
									<span v-if="!displayError">Press the enter key to search</span>
									<span v-else class="text-red-800">Please provide at least 3 characters</span>
								</h2>
							</div>
						</Combobox>
					</DialogPanel>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import {
	Combobox,
	ComboboxInput,
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from "@headlessui/vue";

import { useGlobalStore } from '@/stores/global'
import { mapStores } from 'pinia'


export default defineComponent({
	components: {
		MagnifyingGlassIcon,
		Combobox,
		ComboboxInput,
		// eslint-disable-next-line vue/no-reserved-component-names
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot,
	},
	data() {
		return {
			data: true,
			query: "",
		};
	},
	computed: {
		searchboxState(): boolean {
			return this.globalStore.searchboxOpen
		},
		displayError(): boolean {
			return this.query === "" || this.query.length >= 3
				? false
				: true
		},
		...mapStores(useGlobalStore)
	},
	methods: {
		close(): void {
			this.globalStore.searchboxOpen = false
		},
		onSelect(): void {
			if (this.query.trim().length < 3) return;

			this.close();

			this.$router.push({
				name: "search",
				query: {
					q: this.query,
				},
			});
		},
	},
});
</script>
