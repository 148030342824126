import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useGlobalStore } from "@/stores/global"
import type { AuthType } from '@/types/AuthType'

const createCustomRouter = () => {
	const globalStore = useGlobalStore()

	const router = createRouter({
		history: createWebHistory(import.meta.env.BASE_URL),
		routes: routes,
		scrollBehavior() {
			return { top: 0 }
		}
	})

	router.beforeEach((to, from) => {
		switch (to.meta.requiresAuth as AuthType) {
			case 'requires-auth':
				if (!globalStore.isLoggedIn)
					return { name: 'login', query: { redirect: to.path } }
				break;

			case 'requires-unauth':
				if (globalStore.isLoggedIn)
					return { name: 'home' }
				break;
		}
	})

	return router
}

export { createCustomRouter }