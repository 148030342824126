<template>
	<div class="bg-background-gray">
		<TheHeader />

		<main class="pt-10">
			<RouterView v-slot="{ Component }">
				<transition name="router" mode="out-in">
					<component :key="new Date().toString()" :is="Component" />
				</transition>
			</RouterView>

			<SearchBox />
			<BackToTopButton />
			<CookieConsent />
		</main>

		<TheFooter />

		<LightboxComponent />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TheHeader from '@/components/layout/TheHeader.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import BackToTopButton from '@/components/base/BackToTopButton.vue'
import SearchBox from './components/layout/SearchBox.vue'
import CookieConsent from './components/cookie-consent/CookieConsent.vue'
import LightboxComponent from './components/layout/LightboxComponent.vue'


export default defineComponent({
	components: {
		TheHeader, TheFooter, BackToTopButton, SearchBox, CookieConsent, LightboxComponent
	}
})
</script>


<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

.small-caps {
	font-variant: small-caps;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	font-style: italic;
	background-repeat: no-repeat;
	background-size: cover;
	shape-margin: .75rem;
}
</style>

<style scoped>
.router-enter-active,
.router-leave-active {
	transition: all 200ms ease-out;
}

.router-enter-to {
	opacity: 1;
}

.router-enter-from {
	opacity: 0
}

.router-leave-to {
	opacity: 0;
}

.router-leave-from {
	opacity: 1;
}
</style>


<style>
article h2 {
	@apply text-2xl mb-6 md:text-3xl font-semibold
}

article h3 {
	@apply text-lg mb-3 mt-6 md:text-xl font-semibold text-hp-900;
	font-variant: small-caps;
}

article p {
	@apply mb-4 md:text-lg
}

article li {
	@apply md:text-lg pl-2 ml-4 mb-1;
}

article ul li {
	list-style-type: square;
}

article ol li {
	list-style-type: decimal;
}

article i {
	@apply italic
}

article b {
	@apply font-semibold
}

article hr {
	@apply mb-4
}
</style>