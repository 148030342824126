<template>
	<TransitionRoot :show="menuState" as="template" :appear="true">
		<Dialog as="div" class="fixed z-50 overflow-x-auto lg:hidden font-utile" @close="closeMenu()">
			<div class="fixed inset-0 z-50" />
			
			<TransitionChild
					as="template"
					enter="duration-150 transition transform"
					enter-from="translate-x-full"
					enter-to="translate-x-0"
					leave="duration-150"
					leave-from="translate-x-0"
					leave-to="translate-x-full"
			>
				<DialogPanel
					class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-[20rem] sm:ring-1 sm:ring-gray-900/10">
					<div class="flex justify-between">
						<a class="pt-3 -mt-4 text-2xl font-adobe-caslon-pro text-hp-900">
							Hungarian Pediatrics
						</a>
						<button type="button" class="-mr-2.5 -mt-3.5 rounded-md p-2.5 text-gray-700" @click="closeMenu()">
							<span class="sr-only">Close menu</span>
							<XMarkIcon class="w-6 h-6" aria-hidden="true" />
						</button>
					</div>
					<div class="flow-root mt-6">
						<div class="-my-6 divide-y divide-gray-500/10">
							<ul class="py-6 space-y-2">
								<component @close-mobile-menu="closeMenu()" @click="nav.type !== 'dropdown-menu-item' ? closeMenu() : null" v-for="nav of navigation" :key="nav.name" :is="nav.type" :navigation="nav" />
								<!-- <RouterLink @click="closeMenu()" v-for="item in navigation" :key="item.name" :to="item.href"
									class="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50">{{
										item.name }}</RouterLink> -->
								<a @click="openSearchBox"
									class="flex items-center px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg cursor-pointer hover:bg-gray-50">Search</a>
								<hr>
								<template v-if="globalStore.isLoggedIn">
									<li>
										<InternalMenuItem @click="closeMenu()" to="/profile">Profile</InternalMenuItem>
										<LogoutMenuItem @click="closeMenu()" />
									</li>
								</template>
								<template v-else>
									<InternalMenuItem @click="closeMenu()" to="/login">Login</InternalMenuItem>
									<InternalMenuItem @click="closeMenu()" to="/register">Register</InternalMenuItem>
								</template>
							</ul>
						</div>
					</div>
				</DialogPanel>
			</TransitionChild>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { useGlobalStore } from '@/stores/global'
import { mapStores } from 'pinia'

import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import type { NavigationItem } from '@/types/NavigationItem'
import DropdownMenuItem from './menu/DropdownMenuItem.vue'
import MenuItem from './menu/MenuItem.vue'
import LogoutMenuItem from './menu/LogoutMenuItem.vue'
import InternalMenuItem from './menu/InternalMenuItem.vue'


export default defineComponent({
	components: {
		// eslint-disable-next-line vue/no-reserved-component-names, vue/no-unused-components
		Dialog, DialogPanel, XMarkIcon, TransitionRoot, TransitionChild, DropdownMenuItem, MenuItem, LogoutMenuItem, InternalMenuItem
	},
	emits: ['close', 'open-search-box'],
	props: {
		navigation: {
			type: Object as PropType<NavigationItem[]>,
			required: false
		},
		menuState: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		...mapStores(useGlobalStore)
	},
	methods: {
		closeMenu(): void {
			this.$emit('close')
		},
		openSearchBox(): void {
			this.$emit('open-search-box')
		}
	}
})
</script>